import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import IChildren from 'common/types/IChildren';
import { Outlet, useOutlet, useParams } from 'react-router-dom';
import { Resizable } from 're-resizable';
import IssuesDataGrid from 'issues/components/IssuesDataGrid';
import IssuesBoard from 'issues/components/IssuesBoard';
import IssuesTimeline from 'issues/components/IssuesTimeline';
import IssuesFilterNavigationSidebar from 'issues/components/IssuesFilterNavigationSidebar';
import IssuesViewMode from 'issues/types/IssuesViewMode';
import { Direction } from 're-resizable/lib/resizer';

interface IssuesPanelProps extends ISxProps, IChildren {
}

const FILTER_SIDEBAR_COLLAPSED_WIDTH = 40;
const ISSUES_PANEL_FILTER_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY = 'issues-panel_filter-sidebar-default-width';
const ISSUES_PANEL_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY = 'issues-panel_details-sidebar-default-width';
const ISSUES_PANEL_FILTER_SIDEBAR_COLLAPSED_STORAGE_KEY = 'issues-panel_details-sidebar-collapsed';
export const ISSUES_PANEL_VIEW_MODE_STORAGE_KEY = 'issues-panel_view-mode';

// TODO: create a common component from this and LinkExistingIssuePanel
export default function IssuesPanel({
  sx,
}: IssuesPanelProps) {
  const { viewMode } = useParams<'viewMode'>();

  useEffect(() => {
    if (viewMode) {
      localStorage.setItem(ISSUES_PANEL_VIEW_MODE_STORAGE_KEY, viewMode);
    }
  }, [viewMode]);

  const outlet = useOutlet();
  const [collapsed, setCollapsed] = useState<boolean>(localStorage.getItem(ISSUES_PANEL_FILTER_SIDEBAR_COLLAPSED_STORAGE_KEY) === 'true');

  const [filterSidebarDefaultWidth, setFilterSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem(ISSUES_PANEL_FILTER_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY);
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 200;
  });

  const onResizeStopFilterSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    if (!collapsed) {
      localStorage.setItem(ISSUES_PANEL_FILTER_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY, `${elementRef.clientWidth}`);
      setFilterSidebarDefaultWidth(elementRef.clientWidth);
    }
  }, [collapsed]);

  const [detailsSidebarDefaultWidth, setDetailsSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem(ISSUES_PANEL_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY);
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 375;
  });

  const onResizeStopDetailsSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem(ISSUES_PANEL_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY, `${elementRef.clientWidth}`);
    setDetailsSidebarDefaultWidth(elementRef.clientWidth);
  }, []);

  const filterSidebarResizableRef = useRef<Resizable>(null);
  const onChangeExpanded = useCallback((nextCollapsed: boolean) => {
    setCollapsed(nextCollapsed);
    filterSidebarResizableRef.current?.updateSize({ width: nextCollapsed ? FILTER_SIDEBAR_COLLAPSED_WIDTH : filterSidebarDefaultWidth, height: 'auto' });
    localStorage.setItem(ISSUES_PANEL_FILTER_SIDEBAR_COLLAPSED_STORAGE_KEY, nextCollapsed ? 'true' : 'false');
  }, [filterSidebarDefaultWidth]);

  return (
    <Box id="IssuesPanel" sx={{ ...sx, overflow: 'auto', boxShadow: 'inset -1px 0px 6px -2px rgba(0,0,0,0.3)', flex: '1 1 0', display: 'flex' }}>
      <Resizable
        ref={filterSidebarResizableRef}
        style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
        enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
        defaultSize={{ width: collapsed ? FILTER_SIDEBAR_COLLAPSED_WIDTH : filterSidebarDefaultWidth, height: 'auto' }}
        onResizeStop={onResizeStopFilterSidebar}
        minWidth={collapsed ? FILTER_SIDEBAR_COLLAPSED_WIDTH : 100}
        handleComponent={{ right: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
        handleStyles={{ right: { right: 0 } }}
      >
        <IssuesFilterNavigationSidebar collapsed={collapsed} onChangeCollapsed={onChangeExpanded} />
      </Resizable>
      <Box sx={{ flex: '1 1 0', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        {viewMode === IssuesViewMode.List && <IssuesDataGrid />}
        {viewMode === IssuesViewMode.Board && <IssuesBoard />}
        {viewMode === IssuesViewMode.Timeline && <IssuesTimeline />}
      </Box>
      {!!outlet && (
        <Resizable
          style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
          enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
          defaultSize={{ width: detailsSidebarDefaultWidth, height: 'auto' }}
          onResizeStop={onResizeStopDetailsSidebar}
          minWidth="375px"
          handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
          handleStyles={{ left: { left: 0 } }}
        >
          <Outlet />
        </Resizable>
      )}
    </Box>
  );
}
