import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import UserLanguageUpdateDto from 'users/types/UserLanguageUpdateDto';
import ErrorResponseData from 'api/types/ErrorResponseData';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function useCurrentUserLanguageMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases: labelQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Label);
  return useMutation({
    mutationFn: async (dto: UserLanguageUpdateDto) => {
      await axiosInstance.patch<undefined | ErrorResponseData>('/user/current/language', dto);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['user', 'current'] });

      // When setting the user language there is a race condition between the update to the content-language header
      // taking effect and the labels cache invalidation request being sent.
      // Currently, the request always wins, so the new data is requested in the old language.
      // As a workaround, we just delay invalidating the labels cache.
      // This is dirty, but it is already planned to get rid of content-lanugage-header based translations.
      await new Promise<void>((resolve) => { setTimeout(() => resolve(), 500); });

      await Promise.all(labelQueryKeyBases.map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase })));
    },
  });
}
