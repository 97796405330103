import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { ISSUES_PANEL_VIEW_MODE_STORAGE_KEY } from 'issues/components/IssuesPanel';
import IssuesViewMode from 'issues/types/IssuesViewMode';

export default function IssuesViewModeRedirect() {
  const viewMode = useMemo(() => {
    const storedViewMode = localStorage.getItem(ISSUES_PANEL_VIEW_MODE_STORAGE_KEY);
    if (storedViewMode === IssuesViewMode.List || storedViewMode === IssuesViewMode.Board) {
      return storedViewMode;
    }
    return 'list';
  }, []);
  return (
    <Navigate to={viewMode} />
  );
}
