import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom';
import AutoLoginContainer from 'Containers/AutoLoginContainer/AutoLoginCotainer';
import OpenWindowsClientContainer from 'Containers/OpenWindowsClientContainer/OpenWindowsClientContainer';

// Global styles
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

// Pages
import ProjectsPage from 'projects/components/ProjectsPage';
import DashboardPage from 'dashboard/components/DashboardPage';
import DocumentsPage from 'documents/components/DocumentsPage';
import ModelsPage from 'models/components/ModelsPage';
import Layout from 'setup/components/Layout';
import AuthenticatedRootLayout from 'setup/components/AuthenticatedRootLayout';
import NotFound from 'error/NotFound';
import CollaboratorsPage from 'collaborators/components/CollaboratorsPage';
import RouterErrorBoundary from 'error/RouterErrorBoundary';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'setup/appInsights';
import DocumentsRedirectHub from 'setup/components/DocumentsRedirectHub';
import HomePage from 'setup/components/HomePage';
import RoleSettingsTabPane from 'settings/components/RoleSettingsTabPane';
import SettingsPage from 'settings/components/SettingsPage';
import CollaboratorGroupSettingsTabPane from 'settings/components/CollaboratorGroupSettingsTabPane';
import EncodingSettingsTabPane from 'settings/components/EncodingSettingsTabPane';
import DocumentSettingsTabPane from 'settings/components/DocumentSettingsTabPane';
import IssueSettingsTabPane from 'settings/components/IssueSettingsTabPane';
import SettingsTab from 'settings/types/SettingsTab';
import SettingsHub from 'settings/components/SettingsHub';
import CopyProjectPage from 'projects/components/CopyProjectPage';
import LoginPage from 'users/components/LoginPage';
import ProjectLayout from 'projects/components/ProjectLayout';
import ProjectSettingsTabPane from 'settings/components/ProjectSettingsTabPane';
import PasswordResetLayout from 'users/components/PasswordResetLayout';
import PasswordResetRequestDialog from 'users/components/PasswordResetRequestDialog';
import PasswordResetConfirmDialog from 'users/components/PasswordResetConfirmDialog';
import RegisterPage from 'users/components/RegisterPage';
import ActivateAccountPage from 'users/components/ActivateAccountPage';
import ProjectMetaDataSettingsTabPane from 'settings/components/ProjectMetaDataSettingsTabPane';
import EmailsPage from 'emails/components/EmailsPage';
import EmailsSideBar from 'emails/components/EmailsSideBar';
import EmailsLinkedIssuesDetails from 'emails/components/EmailsLinkedIssuesDetails';
import IssuesPage from 'issues/components/IssuesPage';
import IssueDetailsSidebar from 'issues/components/IssueDetailsSidebar';
import IssuesMutliselectSidebar from 'issues/components/IssuesMutliselectSidebar';
import IssuePrintViewModal from 'issues/components/IssuePrintViewModal';
import IssueLinkedDocumentVersionPreviewModal from 'issues/components/IssueLinkedDocumentVersionPreviewModal';
import DashboardStatisticsPanel from 'dashboard/components/DashboardStatisticsPanel';
import DashboardIssueDetailsPanel from 'dashboard/components/DashboardIssueDetailsPanel';
import IssuesRedirectHub from 'setup/components/IssuesRedirectHub';
import IssuesViewModeRedirect from 'issues/components/IssuesViewModeRedirect';

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<Layout />} ErrorBoundary={RouterErrorBoundary}>
    <Route index element={<HomePage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/activate/:activationCode" element={<ActivateAccountPage />} />
    <Route path="/projects" element={<AuthenticatedRootLayout />}>
      <Route index element={<ProjectsPage />} />
      <Route path=":projectId" element={<ProjectLayout />}>
        <Route index element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<DashboardPage />}>
          <Route index element={<DashboardStatisticsPanel />} />
          <Route path="issue/:issueId" element={<DashboardIssueDetailsPanel />} />
        </Route>
        <Route path="issues">
          <Route index element={<IssuesViewModeRedirect />} />
          <Route path=":viewMode/filter?/:filterId?" element={<IssuesPage />}>
            <Route path="multiselect" element={<IssuesMutliselectSidebar />} />
            <Route path="issue/:issueId" element={<IssueDetailsSidebar />}>
              <Route path="document/:documentVersionId/issue?/:documentVersionIssueId?" element={<IssueLinkedDocumentVersionPreviewModal />} />
            </Route>
            <Route path="print" element={<IssuePrintViewModal />} />
          </Route>
        </Route>
        <Route path="documents">
          <Route index element={<Navigate replace to="all" />} />
          <Route path="folder" element={<Navigate replace to="../all" />} />
          <Route path="archive" element={<Navigate replace to="private" />} />
          <Route path=":documentScopeKey/:documentScopeId?" element={<DocumentsPage />}>
            <Route path="preview?/:documentVersionId?/issue?/:issueId?" element={null} />
          </Route>
        </Route>
        <Route path="collaborators" element={<CollaboratorsPage />} />
        <Route path="settings" element={<SettingsPage />}>
          <Route index element={<SettingsHub />} />
          <Route path={SettingsTab.Project} element={<ProjectSettingsTabPane />} />
          <Route path={SettingsTab.ProjectMetadata} element={<ProjectMetaDataSettingsTabPane />} />
          <Route path={SettingsTab.Roles} element={<RoleSettingsTabPane />} />
          <Route path={SettingsTab.CollaboratorGroups} element={<CollaboratorGroupSettingsTabPane />} />
          <Route path={SettingsTab.Encoding} element={<EncodingSettingsTabPane />} />
          <Route path={SettingsTab.Documents} element={<DocumentSettingsTabPane />} />
          <Route path={SettingsTab.Issues} element={<IssueSettingsTabPane />} />
        </Route>
        <Route path="copy" element={<CopyProjectPage />} />
        <Route path="models" element={<ModelsPage />}>
          <Route index element={<Outlet />} />
          <Route path="components/:globalId" element={<Outlet />} />
          <Route path="issue/:issueId" element={<Outlet />} />
        </Route>
        <Route path="emails" element={<EmailsPage />}>
          <Route path=":emailId" element={<EmailsSideBar />}>
            <Route path="issues/:issueId" element={<EmailsLinkedIssuesDetails />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
    <Route path="/openwindowsclient" element={<OpenWindowsClientContainer />} />
    <Route path="/redirect/:tokenHeader/:tokenPayload/:tokenSignature/:tokenExpires/:tokenRemember/:route" element={<AutoLoginContainer />} />
    <Route path="/redirect/projects/:projectId/documents/:documentVersionId/comment/:documentVersionCommentId" element={<DocumentsRedirectHub />} />
    <Route path="/redirect/projects/:projectId/issues/:issueId" element={<IssuesRedirectHub />} />
    <Route path="/reset-password" element={<PasswordResetLayout />}>
      <Route path="request" element={<PasswordResetRequestDialog />} />
      <Route path=":passwordResetCode" element={<PasswordResetConfirmDialog />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Route>,
));

export default function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <RouterProvider router={router} />
    </AppInsightsContext.Provider>
  );
}
