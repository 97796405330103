import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, AlertTitle, Box, Button, Divider, Link, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'setup/i18n';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useRegistrationApi from 'users/hooks/useRegistrationApi';
import LangType from 'users/types/LangType';
import PasswordValidationPanel from 'users/components/PasswordValidationPanel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

export default function RegisterPage() {
  const { t } = useTranslation('users');
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const activationCode = useMemo(() => searchParams.get('activationCode'), [searchParams]);

  useEffect(() => {
    if (activationCode) {
      navigate(`/activate/${activationCode}`);
    }
  }, [activationCode, navigate, searchParams]);

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const [email, setEmail] = useState('');
  const [emailHasError, setEmailHasError] = useState(false);
  const onChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmailHasError(false);
    setErrorMessage(undefined);
    setEmail(event.target.value);
  }, []);
  const [password, setPassword] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState<boolean | undefined>(undefined);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const onChangePassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPasswordInvalid(undefined);
    setPasswordHasError(false);
    setErrorMessage(undefined);
    setPassword(event.target.value);
  }, []);
  const [passwordRepetition, setPasswordRepetition] = useState('');
  const [passwordRepetitionHasError, setPasswordRepetitionHasError] = useState(false);
  const onChangePasswordRepetition = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPasswordRepetitionHasError(false);
    setErrorMessage(undefined);
    setPasswordRepetition(event.target.value);
  }, []);
  const [companyName, setCompanyName] = useState('');
  const [companyNameHasError, setCompanyNameHasError] = useState(false);
  const onChangeCompanyName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCompanyNameHasError(false);
    setErrorMessage(undefined);
    setCompanyName(event.target.value);
  }, []);
  const [firstName, setFirstName] = useState('');
  const [firstNameHasError, setFirstNameHasError] = useState(false);
  const onChangeFirstName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFirstNameHasError(false);
    setErrorMessage(undefined);
    setFirstName(event.target.value);
  }, []);
  const [lastName, setLastName] = useState('');
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const onChangeLastName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLastNameHasError(false);
    setErrorMessage(undefined);
    setLastName(event.target.value);
  }, []);
  const [phone, setPhone] = useState('');
  const [phoneHasError, setPhoneHasError] = useState(false);
  const onChangePhone = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPhoneHasError(false);
    setErrorMessage(undefined);
    setPhone(event.target.value);
  }, []);

  const onChangeNewPasswordValid = useCallback((valid: boolean | undefined) => setPasswordInvalid(!!password.length && valid === false), [password.length]);

  const [activationPromptOpen, setActivationPromptOpen] = useState(false);
  const { signUp } = useRegistrationApi();
  const onClickSignUp = useCallback(async () => {
    try {
      let requiredFieldMissing = false;
      if (!email.length) {
        setEmailHasError(true);
        requiredFieldMissing = true;
      }
      if (!companyName.length) {
        setCompanyNameHasError(true);
        requiredFieldMissing = true;
      }
      if (!firstName.length) {
        setFirstNameHasError(true);
        requiredFieldMissing = true;
      }
      if (!lastName.length) {
        setLastNameHasError(true);
        requiredFieldMissing = true;
      }
      if (!password.length) {
        setPasswordHasError(true);
        requiredFieldMissing = true;
      }
      if (!passwordRepetition.length) {
        setPasswordRepetitionHasError(true);
        requiredFieldMissing = true;
      }
      const errors = [];
      if (requiredFieldMissing) {
        errors.push(t('register-page_required-field-missing-error-message', 'Please fill out all fields that are required to create an account.'));
      }
      if (passwordInvalid) {
        errors.push(t('register-page_password-invalid-error-message', 'Password does not meet the criteria.'));
      }
      if (password !== passwordRepetition) {
        errors.push(t('register-page_password-mismatch-error-message', 'Password and password repetition do not match.'));
      }
      if (errors.length) {
        throw new Error(errors.join('\n'));
      }
      await signUp({
        companyName,
        email,
        firstName,
        lastName,
        langType: i18n.language.toLowerCase().startsWith('de') ? LangType.German : LangType.English,
        password,
        phone,
      });
      setActivationPromptOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [companyName, email, firstName, getRequestErrorMessage, lastName, password, passwordInvalid, passwordRepetition, phone, signUp, t]);

  const onChangeLanguage = useCallback((event: React.MouseEvent<HTMLElement>, value: 'de-DE' | 'en-US') => {
    i18n.changeLanguage(value);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(navigator.language.startsWith('de') ? 'de-DE' : 'en-US');
  }, []);

  if (activationCode) return <CenteredCircularProgress />;

  return (
    <Box
      id="RegisterPage"
      sx={{
        backgroundColor: theme.palette.secondary.light,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        p: 2,
      }}
      >
        <Box sx={{
          maxWidth: 800,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/img/visoplan-logo.svg" alt="logo" style={{ width: '250px' }} />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 2px 8px -3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            p: 5,
            gap: 4,
          }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h1" sx={{ mr: 'auto' }}>{t('register-page_title', 'Create Account')}</Typography>
              <ToggleButtonGroup
                size="small"
                value={i18n.language}
                exclusive
                onChange={onChangeLanguage}
              >
                <ToggleButton value="de-DE">
                  DE
                </ToggleButton>
                <ToggleButton value="en-US">
                  EN
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {!activationPromptOpen && (
              <>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '2fr 3fr',
                  columnGap: 5,
                  rowGap: 2,
                }}
                >
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                  >
                    <TextField
                      value={email}
                      onChange={onChangeEmail}
                      label={t('register-page_email-text-field-label', 'Email')}
                      autoComplete="username"
                      name="register-page_email-text-field"
                      error={emailHasError}
                    />
                    <TextField
                      value={password}
                      onChange={onChangePassword}
                      label={t('register-page_password-text-field-label', 'Password')}
                      name="register-page_password-text-field"
                      autoComplete="new-password"
                      error={passwordHasError || passwordInvalid}
                      type="password"
                    />
                    <TextField
                      value={passwordRepetition}
                      onChange={onChangePasswordRepetition}
                      label={t('register-page_password-repetition-text-field-label', 'Repeat Password')}
                      name="register-page_password-repetition-text-field"
                      autoComplete="new-password"
                      error={passwordRepetitionHasError}
                      type="password"
                    />
                    <Divider />
                    <TextField
                      value={firstName}
                      onChange={onChangeFirstName}
                      label={t('register-page_first-name-text-field-label', 'First Name')}
                      autoComplete="given-name"
                      name="register-first-name-text-field"
                      error={firstNameHasError}
                    />
                    <TextField
                      value={lastName}
                      onChange={onChangeLastName}
                      label={t('register-page_last-name-text-field-label', 'Last Name')}
                      autoComplete="family-name"
                      name="register-last-name-text-field"
                      error={lastNameHasError}
                    />
                    <TextField
                      value={companyName}
                      onChange={onChangeCompanyName}
                      label={t('register-page_company-name-text-field-label', 'Company')}
                      autoComplete="organization"
                      name="register-company-name-text-field"
                      error={companyNameHasError}
                    />
                    <TextField
                      value={phone}
                      onChange={onChangePhone}
                      label={t('register-page_phone-text-field-label', 'Phone Number (optional)')}
                      autoComplete="tel"
                      name="register-phone-text-field"
                      error={phoneHasError}
                    />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                  >
                    <PasswordValidationPanel newPassword={password} onChangeNewPasswordValid={onChangeNewPasswordValid} />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
                  <Button
                    onClick={onClickSignUp}
                    variant="contained"
                    color="primary"
                    disabled={!!errorMessage || passwordInvalid === undefined}
                  >
                    {t('register-page_confirm-button-label', 'Create Account')}
                  </Button>
                </Box>
              </>
            )}
            {!!activationPromptOpen && (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
              >
                <Alert severity="info">
                  <AlertTitle>{t('register-page_activation-prompt-title', 'Please check your email inbox')}</AlertTitle>
                  <Trans
                    t={t}
                    i18nKey="register-page_activation-prompt-message"
                    defaults="We have sent an activation email to <strong>{{email}}</strong>. Click the link in that email to complete creating your account.<br /><br />You can close this browser window."
                    values={{ email }}
                    components={{ strong: <strong />, br: <br /> }}
                  />
                </Alert>
              </Box>
            )}
          </Box>
          {!activationPromptOpen && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography>
                <Trans
                  t={t}
                  i18nKey="register-page_terms-and-service-notice"
                  defaults="With your registration, you agree to our <terms>our terms and conditions</terms>, our <security>security guidelines</security>, and our <privacy>privacy notice</privacy>."
                  components={{
                    terms: <Link href="http://docs.visoplan.de/AGB_Visoplan_2021.pdf" rel="noreferrer" target="_blank" />,
                    security: <Link href="http://docs.visoplan.de/Visoplan_Acceptable_Use_Policy_2021.pdf" rel="noreferrer" target="_blank" />,
                    privacy: <Link href="http://docs.visoplan.de/Datenschutzbestimmungen%20der%20Visoplan%20GmbH.pdf" rel="noreferrer" target="_blank" />,
                  }}
                />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
