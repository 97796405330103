import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import CreateLabelDto from 'labels/types/CreateLabelDto';
import LabelDto from 'labels/types/LabelDto';
import { useCallback } from 'react';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import LabelType from 'labels/types/LabelType';

export default function useLabelCreateMutation() {
  const currentProjectId = useCurrentProjectId();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { getOdataQueryKey, queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Label);
  const mutationFn = useCallback(async (dto: CreateLabelDto) => {
    const { data: label } = await axiosInstance.post<LabelDto>(`${ApiEndpoint.Label}`, dto);
    return label;
  }, [axiosInstance]);
  const onMutate = useCallback(async (dto: CreateLabelDto) => {
    if (!currentProjectId) throw new Error('dependency error');
    // optimistic update. this is primarily for the create-select-dropdown in the DMS to feel snappy
    const odataQueryKey = getOdataQueryKey({ filter: { type: LabelType[dto.type], isDeleted: false }, orderBy: 'order asc' });
    await queryClient.cancelQueries({ queryKey: odataQueryKey });
    const now = new Date().toISOString();
    const optimisticEntity: LabelDto = {
      ...dto,
      id: '',
      projectId: currentProjectId,
      originalName: dto.name,
      isDefault: false,
      order: Number.POSITIVE_INFINITY,
      parentId: dto.parentId,
      creationDate: now,
      editDate: now,
      isDeleted: false,
    };
    queryClient.setQueryData<LabelDto[] | undefined>(odataQueryKey, (existingTags) => {
      if (!Array.isArray(existingTags)) return [optimisticEntity];
      return [...existingTags, optimisticEntity];
    });
  }, [currentProjectId, getOdataQueryKey, queryClient]);
  const onSettled = useCallback((data: LabelDto | undefined) => {
    if (!currentProjectId) throw new Error('currentProjectId is undefined');
    if (!data) throw new Error('data is undefined');
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [currentProjectId, queryKeyBases, queryClient]);
  return useMutation<LabelDto, unknown, CreateLabelDto>({
    mutationFn,
    onMutate,
    onSettled,
  });
}
