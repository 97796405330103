enum NamingSchemeFileType {
  PDF = '.pdf',
  CSV = '.csv',
  ABS = '.abs',
  IFC = '.ifc',
  DOCX = '.docx',
  DOCM = '.docm',
  DOC = '.doc',
  DOTX = '.dotx',
  DOTM = '.dotm',
  DOT = '.dot',
  XPS = '.xps',
  MHT = '.mht',
  MHTML = '.mhtml',
  HTM = '.htm',
  HTML = '.html',
  RTF = '.rtf',
  TXT = '.txt',
  XML = '.xml',
  ODT = '.odt',
  XLSX = '.xlsx',
  XLS = '.xls',
  PPSX = '.ppsx',
  PPSM = '.ppsm',
  PPS = '.pps',
  PPA = '.ppa',
  PPTX = '.pptx',
  PPTM = '.pptm',
  PPT = '.ppt',
  POTX = '.potx',
  POTM = '.potm',
  POT = '.pot',
  THMX = '.thmx',
  MP4 = '.mp4',
  WMV = '.wmv',
  DWG = '.dwg',
  DXF = '.dxf',
  PLN = '.pln',
  PLA = '.pla',
  RVT = '.rvt',
  SMC = '.smc',
  NWF = '.nwf',
  NWD = '.nwd',
  BMP = '.bmp',
  GIF = '.gif',
  ICO = '.ico',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PNG = '.png',
  PS = '.ps',
  PSD = '.psd',
  SVG = '.svg',
  TIF = '.tif',
  TIFF = '.tiff',
  ZIP = '.zip',
}

export default NamingSchemeFileType;
