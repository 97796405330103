import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TimeFrame from 'query-filters/types/TimeFrame';
import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import DateRange from 'common/types/DateRange';
import DateRangeKind from 'common/types/DateRangeKind';
import useTimeFrameFromToDates from 'common/hooks/useTimeFrameDates';
import useTimeFrameTranslations from 'common/hooks/useTimeFrameTranslations';
import ISxProps from 'common/types/ISxProps';
import moment, { Moment } from 'moment';

enum SpecialTimeFrame {
  Custom = -1,
  Undefined = -2,
}

type TimeFrameSelection = TimeFrame | SpecialTimeFrame;

interface DateRangePickerProps extends ISxProps {
  id?: string | undefined,
  value: DateRange | undefined,
  onChange: (value: DateRange | undefined) => void,
  hideAny?: boolean | undefined,
  noFutureDates?: boolean | undefined,
}

export default function DateRangePicker({
  value,
  onChange,
  hideAny,
  id,
  sx,
  noFutureDates,
}: DateRangePickerProps) {
  const { t } = useTranslation('common');
  const getTimeFrameDates = useTimeFrameFromToDates();
  const timeFrameTranslations = useTimeFrameTranslations();
  const [timeFrame, setTimeFrame] = useState<TimeFrameSelection>(SpecialTimeFrame.Undefined);
  const [from, setFrom] = useState<Moment | undefined>(undefined);
  const [to, setTo] = useState<Moment | undefined>(undefined);

  const notifyParent = useCallback((timeFrameValue: TimeFrameSelection, fromValue: Moment | undefined, toValue: Moment | undefined) => {
    if (timeFrameValue === SpecialTimeFrame.Custom) {
      if (fromValue !== undefined || toValue !== null) {
        onChange({
          dateRangeKind: DateRangeKind.Custom,
          from: fromValue?.toDate(),
          to: toValue?.toDate(),
        });
      }
    } else if (timeFrameValue !== SpecialTimeFrame.Undefined) {
      onChange({
        dateRangeKind: DateRangeKind.Relative,
        timeFrame: timeFrameValue,
      });
    } else {
      onChange(undefined);
    }
  }, [onChange]);

  const handleChangeTimeFrame = useCallback((timeFrameValue: TimeFrameSelection) => {
    if (timeFrame === timeFrameValue) return;
    let fromValue: Moment | undefined;
    let toValue: Moment | undefined;

    if (timeFrameValue === SpecialTimeFrame.Undefined) {
      fromValue = undefined;
      toValue = undefined;
    } else if (timeFrameValue === SpecialTimeFrame.Custom) {
      fromValue = from;
      toValue = to;
    } else {
      const timeFrameDates = getTimeFrameDates(timeFrameValue);
      fromValue = timeFrameDates.fromValue ? moment(timeFrameDates.fromValue) : undefined;
      toValue = timeFrameDates.toValue ? moment(timeFrameDates.toValue) : undefined;
    }

    setTimeFrame(timeFrameValue);
    setFrom(fromValue);
    setTo(toValue);
    notifyParent(timeFrameValue, fromValue, toValue);
  }, [from, getTimeFrameDates, notifyParent, timeFrame, to]);

  const handleChangeFrom = useCallback((nextFrom: Moment | undefined) => {
    if (from === nextFrom) return;
    setFrom(nextFrom);
    notifyParent(timeFrame, nextFrom, to);
  }, [from, notifyParent, timeFrame, to]);

  const handleChangeTo = useCallback((nextTo: Moment | undefined) => {
    if (to === nextTo) return;
    setTo(nextTo);
    notifyParent(timeFrame, from, nextTo);
  }, [notifyParent, timeFrame, from, to]);

  useEffect(() => {
    if (!value) {
      setTimeFrame(SpecialTimeFrame.Undefined);
      setFrom(undefined);
      setTo(undefined);
    } else if (value.dateRangeKind === DateRangeKind.Relative) {
      const timeFrameDates = getTimeFrameDates(value.timeFrame);
      const fromValue = timeFrameDates.fromValue ? moment(timeFrameDates.fromValue) : undefined;
      const toValue = timeFrameDates.toValue ? moment(timeFrameDates.toValue) : undefined;
      setTimeFrame(value.timeFrame);
      setFrom(fromValue);
      setTo(toValue);
    } else if (value.dateRangeKind === DateRangeKind.Custom) {
      setTimeFrame(SpecialTimeFrame.Custom);
      setFrom(moment(value.from));
      setTo(moment(value.to));
    }
  }, [getTimeFrameDates, value]);

  const maxDateTo = useMemo(() => (noFutureDates ? moment() : undefined), [noFutureDates]);

  const availableRelativeFrameValues = useMemo<TimeFrame[]>(() => [
    TimeFrame.Today,
    TimeFrame.Yesterday,
    TimeFrame.ThisWeek,
    TimeFrame.LastWeek,
    TimeFrame.ThisMonth,
    TimeFrame.LastMonth,
    ...(noFutureDates ? [] : [
      TimeFrame.Past,
      TimeFrame.Tomorrow,
      TimeFrame.NextWeek,
      TimeFrame.NextMonth,
      TimeFrame.Future,
    ]),
  ], [noFutureDates]);

  return (
    <Box
      id={id}
      sx={{ display: 'grid', gridTemplateColumns: '.5fr .5fr', columnGap: 1, rowGap: 2, mt: 1, ...sx }}
    >
      <FormControl sx={{ width: '100%', gridColumn: '1 / 3' }}>
        <InputLabel id="date-range-picker_timeframe-label">{t('date-range-picker_timeframe-label', 'Time Frame')}</InputLabel>
        <Select
          labelId="date-range-picker_timeframe-label"
          label={t('date-range-picker_timeframe-label', 'Time Frame')}
          value={timeFrame !== SpecialTimeFrame.Undefined ? timeFrame : ''}
          onChange={(e) => handleChangeTimeFrame(e.target.value as TimeFrameSelection)}
        >
          {!hideAny && <MenuItem value={SpecialTimeFrame.Undefined}><em>{t('date-range-picker_item-any', 'Any')}</em></MenuItem>}
          <MenuItem value={SpecialTimeFrame.Custom}>{t('date-range-picker_item-custom', 'Custom')}</MenuItem>
          {availableRelativeFrameValues.map((relativeTimeFrame) => (
            <MenuItem key={relativeTimeFrame} value={relativeTimeFrame}>{timeFrameTranslations[relativeTimeFrame]}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizedDatePicker
        label={t('date-range-picker_from', 'From')}
        value={from}
        disabled={timeFrame !== SpecialTimeFrame.Custom}
        onChange={handleChangeFrom}
        maxDate={to}
      />
      <LocalizedDatePicker
        label={t('date-range-picker_to', 'To')}
        value={to}
        disabled={timeFrame !== SpecialTimeFrame.Custom}
        onChange={handleChangeTo}
        minDate={from}
        maxDate={maxDateTo}
      />
    </Box>
  );
}
