import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import usePasswordResetConfirmMutation from 'users/hooks/usePasswordResetConfirmMutation';
import PasswordValidationPanel from 'users/components/PasswordValidationPanel';

export default function PasswordResetConfirmDialog() {
  const { t } = useTranslation('users');
  const navigate = useNavigate();
  const { passwordResetCode } = useParams<'passwordResetCode'>();
  const { mutateAsync: mutatePasswordConfirmAsync, isPending: isLoadingPasswordResetMutation } = usePasswordResetConfirmMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const onCloseSuccessDialog = useCallback(() => navigate('/login'), [navigate]);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepetition, setNewPasswordRepetition] = useState<string>('');
  const [newPasswordRepetitionError, setNewPasswordRepetitionError] = useState<string | undefined>(undefined);
  const [newPasswordValid, setNewPasswordValid] = useState<boolean | undefined>(undefined);
  const onChangeNewPasswordValid = useCallback((valid: boolean | undefined) => setNewPasswordValid(valid), []);
  const newPasswordHasError = useMemo(() => !!newPassword.length && newPasswordValid === false, [newPassword.length, newPasswordValid]);

  const confirmDisabled = useMemo(() => Boolean(
    isLoadingPasswordResetMutation
    || !newPassword.length
    || !newPasswordRepetition.length
    || !newPasswordValid
    || !!newPasswordRepetitionError,
  ), [isLoadingPasswordResetMutation, newPassword.length, newPasswordRepetition.length, newPasswordValid, newPasswordRepetitionError]);

  const onClickConfirm = useCallback(async () => {
    if (!passwordResetCode) return;
    try {
      await mutatePasswordConfirmAsync({
        code: passwordResetCode,
        password: newPassword,
      });
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, mutatePasswordConfirmAsync, newPassword, passwordResetCode]);

  const onChangeNewPassword = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  }, []);

  const onChangeNewPasswordRepetition = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordRepetition(event.target.value);
    if (newPassword !== event.target.value) {
      setNewPasswordRepetitionError(t('password-reset-confirm-dialog_repetition-error', 'Passwords do not match.'));
    } else {
      setNewPasswordRepetitionError(undefined);
    }
  }, [newPassword, t]);

  return (
    <Dialog open id="PasswordResetConfirmDialog" hideBackdrop PaperProps={{ sx: { maxWidth: '1000px' } }}>
      <DialogTitle>{t('password-reset-confirm-dialog_title', 'Create New Password')}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 4, pt: 1 }}>
          <PasswordValidationPanel newPassword={newPassword} onChangeNewPasswordValid={onChangeNewPasswordValid} sx={{ flexShrink: 0, width: '500px' }} />
          <Box sx={{ flexShrink: 0, width: '300px', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label={t('password-reset-confirm-dialog_new-password-label', 'New password')}
              value={newPassword ?? ''}
              onChange={onChangeNewPassword}
              disabled={isLoadingPasswordResetMutation}
              error={newPasswordHasError}
              type="password"
              name="new-password"
              autoComplete="new-password"
            />
            <TextField
              label={t('password-reset-confirm-dialog_repeat-new-password-label', 'Repeat new password')}
              value={newPasswordRepetition ?? ''}
              onChange={onChangeNewPasswordRepetition}
              disabled={isLoadingPasswordResetMutation}
              error={!!newPasswordRepetitionError}
              helperText={newPasswordRepetitionError}
              type="password"
            />
            <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={confirmDisabled}>
              {t('password-reset-confirm-dialog_confirm-button-label', 'Confirm')}
            </Button>
          </Box>
        </Box>
        {!!errorMessage && <Alert severity="error" sx={{ mt: 2 }} onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
        {!!successDialogOpen && (
          <Dialog open>
            <DialogTitle>{t('password-reset-confirm-dialog_success-dialog-title', 'Password Changed')}</DialogTitle>
            <DialogContent>
              <Alert severity="success">{t('password-reset-confirm-dialog_success-dialog-title', 'The password for your user account has been changed.')}</Alert>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={onCloseSuccessDialog}>{t('password-reset-confirm-dialog_success-dialog-close-button-label', 'Go to Log In')}</Button>
            </DialogActions>
          </Dialog>
        )}
      </DialogContent>
    </Dialog>
  );
}
