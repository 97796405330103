import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type DocumentVersionColumnTranslations = {
  [key in keyof DocumentVersionDto]?: string;
};

export default function useColumnNameTranslations() {
  const { t } = useTranslation('documents');
  return useMemo<DocumentVersionColumnTranslations>(() => ({
    id: t('use-column-name-translations_id', 'ID'),
    timeStamp: t('use-column-name-translations_timeStamp', 'Time Stamp'),
    versionNumber: t('use-column-name-translations_versionNumber', 'Version'),
    disciplines: t('use-column-name-translations_disciplines', 'Disciplines'),
    building: t('use-column-name-translations_building', 'Building'),
    uploadDate: t('use-column-name-translations_uploadDate', 'Upload Date'),
    editDate: t('use-column-name-translations_editDate', 'Edit Date'),
    creator: t('use-column-name-translations_creator', 'Creator'),
    editor: t('use-column-name-translations_editor', 'Editor'),
    hasFolderAccess: t('use-column-name-translations_hasFolderAccess', 'Has Folder Access'),
    commentIds: t('use-column-name-translations_commentIds', 'Comments'),
    tags: t('use-column-name-translations_tags', 'Tags'),
    linkedIssueIds: t('use-column-name-translations_linkedIssueIds', 'Version Issues'),
    isPrivate: t('use-column-name-translations_isPrivate', 'Is Private'),
    previewFileId: t('use-column-name-translations_previewFileId', 'Preview File'),
    isArchived: t('use-column-name-translations_isArchived', 'Is Archived'),
    workPhase: t('use-column-name-translations_workPhase', 'Work Phase'),
    creationDate: t('use-column-name-translations_creationDate', 'Creation Date'),
    floor: t('use-column-name-translations_floor', 'Floor'),
    folderId: t('use-column-name-translations_folderId', 'Folder'),
    name: t('use-column-name-translations_name', 'Name'),
    fileType: t('use-column-name-translations_fileType', 'File Type'),
    originalFileName: t('use-column-name-translations_originalFileName', 'Original File Name'),
    status: t('use-column-name-translations_status', 'Status'),
    fileId: t('use-column-name-translations_fileId', 'File'),
    pdfAnnotationIds: t('use-column-name-translations_pdfAnnotationIds', 'Annotations'),
    fileSize: t('use-column-name-translations_fileSize', 'File Size'),
    modelId: t('use-column-name-translations_modelId', 'Model'),
    documentImportStatus: t('use-column-name-translations_documentImportStatus', 'Import Status'),
    importStartDate: t('use-column-name-translations_importStartDate', 'Import Start Date'),
    importFinishDate: t('use-column-name-translations_importFinishDate', 'Import Finish Date'),
    projectId: t('use-column-name-translations_projectId', 'Project'),
    normalizedName: t('use-column-name-translations_normalizedName', 'Normalized Name'),
    folderPath: t('use-column-name-translations_folderPath', 'Folder Path'),
    isNewest: t('use-column-name-translations_isNewest', 'Is Newest'),
    documentLinkedIssueIds: t('use-column-name-translations_documentLinkedIssueIds', 'Linked Issues'),
    previewLink: t('use-column-name-translations_previewLink', 'Preview Link'),
    downloadLink: t('use-column-name-translations_downloadLink', 'Download Link'),
    dwgConversionError: t('use-column-name-translations_dwgConversionError', 'DWG Error'),
  }), [t]);
}
