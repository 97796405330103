import { useCallback } from 'react';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import i18next from 'i18next';
import StampPosition from 'documents/types/StampPosition';
import StampStrings from 'documents/types/StampStrings';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import { WEBVIEWER_LICENSE_KEY } from 'documents-details/contexts/DocumentViewerContextProvider';
import useDmsSettingsQuery from 'documents/hooks/useDmsSettingsQuery';

async function getTextWidth(builder: Core.PDFNet.ElementBuilder, text: string, font: Core.PDFNet.Font, fontSize: number): Promise<number> {
  const textElement = await builder.createTextRun(text, font, fontSize);
  const bbox = await textElement.getBBox();
  return await bbox.width();
}

async function generateStampDocument(instance: WebViewerInstance, strings: StampStrings) {
  const { PDFNet } = instance.Core;
  await PDFNet.initialize();
  const doc = await PDFNet.PDFDoc.create();
  const builder = await PDFNet.ElementBuilder.create();
  const writer = await PDFNet.ElementWriter.create();
  const projectNameLength = await getTextWidth(builder, strings.projectName, await PDFNet.Font.create(doc, PDFNet.Font.StandardType1Font.e_helvetica_bold), 9) + 40;
  const width = Math.max(projectNameLength, 180);
  const pageRect = await PDFNet.Rect.init(0, 0, width, 64);
  const page = await doc.pageCreate(pageRect);
  writer.beginOnPage(page);

  const getLogoPart = async (coordinates: number[], color: number[], transform: Core.PDFNet.Matrix2D) => {
    await builder.pathBegin();
    await builder.moveTo(coordinates[0], coordinates[1]);
    await builder.lineTo(coordinates[2], coordinates[3]);
    await builder.lineTo(coordinates[4], coordinates[5]);
    await builder.closePath();
    const path = await builder.pathEnd();
    await path.setPathFill(true);
    await path.setPathStroke(false);
    const gState = await path.getGState();
    gState.setTransformMatrix(transform);
    await gState.setFillColorSpace(await PDFNet.ColorSpace.createDeviceRGB());
    await gState.setFillColorWithColorPt(await PDFNet.ColorPt.init(color[0], color[1], color[2]));
    await writer.writePlacedElement(path);
    return path;
  };

  // write background with border
  const rect = await builder.createRect(0.5, 0.5, await pageRect.width() - 1, await pageRect.height() - 1);
  const rectGState = await rect.getGState();
  await rect.setPathStroke(true);
  await rectGState.setStrokeColorSpace(await PDFNet.ColorSpace.createDeviceRGB());
  await rectGState.setStrokeColorWithColorPt(await PDFNet.ColorPt.init(0.89, 0.89, 0.89));
  await rect.setPathFill(true);
  await rectGState.setFillColorSpace(await PDFNet.ColorSpace.createDeviceRGB());
  await rectGState.setFillColorWithColorPt(await PDFNet.ColorPt.init(1, 1, 1));
  await rectGState.setFillOpacity(0.9);
  await writer.writePlacedElement(rect);
  await rectGState.setFillOpacity(1);

  // write logo
  const logoTransform = await PDFNet.Matrix2D.createIdentityMatrix();
  await logoTransform.scale(0.2, 0.2);
  await logoTransform.translate(6, 50.5);
  const logoPartLeft = await getLogoPart([0.0, 0.0, 27.3, 0.0, 13.6, 23.6], [0, 0.3019608, 0.5843138], logoTransform);
  writer.writePlacedElement(logoPartLeft);
  const logoPartTop = await getLogoPart([17.1, 36.2, 44.4, 36.2, 30.7, 12.6], [0, 0.7215686, 0.7764706], logoTransform);
  writer.writePlacedElement(logoPartTop);
  const logoPartRight = await getLogoPart([35.1, 0.0, 62.3, 0.0, 48.7, 23.6], [0.6666667, 0.0, 0.4823529], logoTransform);
  writer.writePlacedElement(logoPartRight);

  // write divider
  await builder.pathBegin();
  await builder.moveTo(2, 44);
  await builder.lineTo(await pageRect.width() - 2, 44);
  const line = await builder.pathEnd();
  await line.setPathStroke(true);
  await writer.writeElement(line);

  // prepare writing text
  const getTextRun = async (text: string, x: number, y: number) => {
    const run = await builder.createUnicodeTextRun(text);
    const gstate = await run.getGState();
    await gstate.setCharSpacing(-1.15);
    await run.setTextMatrixEntries(1, 0, 0, 1, x, y);
    return run;
  };

  // non-bold text pass
  const normalFont = await PDFNet.Font.create(doc, PDFNet.Font.StandardType1Font.e_helvetica);
  const normalTextElement = await builder.createTextBeginWithFont(normalFont, 8);
  const normalTextGState = await normalTextElement.getGState();
  await normalTextGState.setFillColorSpace(await PDFNet.ColorSpace.createDeviceRGB());
  await normalTextGState.setFillColorWithColorPt(await PDFNet.ColorPt.init(0, 0, 0));
  await writer.writeElement(normalTextElement);

  // write download date label
  const downloadDateLabelRun = await getTextRun(strings.downloadDate.label, 6, 31);
  await writer.writeElement(downloadDateLabelRun);

  // write status label
  const statusLabelRun = await getTextRun(strings.status.label, 6, 20);
  await writer.writeElement(statusLabelRun);

  // write version number label
  const versionNumberLabelRun = await getTextRun(strings.versionNumber.label, 6, 9);
  await writer.writeElement(versionNumberLabelRun);

  // finish writing normal text
  await writer.writeElement(await builder.createTextEnd());

  // bold 9pt text pass
  const bold9Font = await PDFNet.Font.create(doc, PDFNet.Font.StandardType1Font.e_helvetica_bold);
  const bold9TextElement = await builder.createTextBeginWithFont(bold9Font, 9);
  const bold9TextGState = await bold9TextElement.getGState();
  await bold9TextGState.setFillColorSpace(await PDFNet.ColorSpace.createDeviceRGB());
  await bold9TextGState.setFillColorWithColorPt(await PDFNet.ColorPt.init(0, 0, 0));
  await writer.writeElement(bold9TextElement);

  // write project name
  const projectNameLabelRun = await getTextRun(strings.projectName, 22, 50.5);
  await writer.writeElement(projectNameLabelRun);

  // finsih bold 9pt pass
  await writer.writeElement(await builder.createTextEnd());

  // bold 8pt text pass
  const bold8Font = await PDFNet.Font.create(doc, PDFNet.Font.StandardType1Font.e_helvetica_bold);
  const bold8TextElement = await builder.createTextBeginWithFont(bold8Font, 8);
  const bold8TextGState = await bold8TextElement.getGState();
  await bold8TextGState.setFillColorSpace(await PDFNet.ColorSpace.createDeviceRGB());
  await bold8TextGState.setFillColorWithColorPt(await PDFNet.ColorPt.init(0, 0, 0));
  await writer.writeElement(bold8TextElement);

  const valuesOffsetX = strings.locale === 'en-US' ? 52 : 70;

  // write download date value
  const downloadDateValueRun = await getTextRun(strings.downloadDate.value, valuesOffsetX, 31);
  await writer.writeElement(downloadDateValueRun);

  // write status value
  const statusValueRun = await getTextRun(strings.status.value, valuesOffsetX, 20);
  await writer.writeElement(statusValueRun);

  // write version number value
  const versionNumberValueRun = await getTextRun(strings.versionNumber.value, valuesOffsetX, 9);
  await writer.writeElement(versionNumberValueRun);

  // finish writing bold text
  await writer.writeElement(await builder.createTextEnd());

  await writer.end();
  await doc.pagePushBack(page);

  // // debug: flush the stamp PDF as a file download
  // // (this is deliberately kept in the code for later debugging)
  // const data = await doc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);
  // const blob = new Blob([data.buffer], { type: 'application/octet-stream' });
  // const a = document.createElement('a');
  // document.body.appendChild(a);
  // const url = window.URL.createObjectURL(blob);
  // a.href = url;
  // a.download = 'stamp.pdf';
  // a.click();
  // setTimeout(() => {
  //   window.URL.revokeObjectURL(url);
  //   document.body.removeChild(a);
  // }, 0);

  return doc;
}

export default function useStampInsertionDownloadFunction() {
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: dmsSettings } = useDmsSettingsQuery();

  return useCallback((instance: WebViewerInstance, statusText: string, versionText: string) => async (options: {
    xfdfString?: string | undefined;
    flatten?: boolean | undefined;
    finishedWithDocument?: boolean | undefined;
    printDocument?: boolean | undefined;
    downloadType?: string | undefined;
    flags?: number | undefined;
    password?: string | undefined;
    encryptionAlgorithmType?: number | undefined;
    includeAnnotations?: boolean | undefined;
  } | undefined) => {
    const core = instance.Core;
    const { documentViewer } = core;
    const { t } = i18next;

    let result = new ArrayBuffer(0);

    const currentDocument = documentViewer.getDocument();
    const pdfDoc = await currentDocument?.getPDFDoc();
    if (!pdfDoc) return result;

    await core.PDFNet.runWithCleanup(async () => {
      // copy the PDF document via a buffer to break the connection to the viewer's current document.
      // there is also PDFNet.PDFDoc.createShallowCopy() but manipulating the copy seems to also manipulate the original for some reason.
      const pdfDocData = await pdfDoc.saveMemoryBuffer(core.PDFNet.SDFDoc.SaveOptions.e_linearized);
      const pdfDocCopyForDownload = await core.PDFNet.PDFDoc.createFromBuffer(pdfDocData);
      pdfDocCopyForDownload.lock();

      // don't include annotations if explicitly disabled. By default we want to include annotations.
      // If downloading via the in-viewer download button (rather than our external download button), options?.includeAnnotations will be undefined, but we still want to include annotations
      // options?.includeAnnotations will be explicitly false when clicking our external download button with the "include annotations" checkbox unchecked.
      if (options?.includeAnnotations !== false && options?.xfdfString) {
        const fdfDoc = await core.PDFNet.FDFDoc.createFromXFDF(options?.xfdfString);
        await pdfDocCopyForDownload.fdfMerge(fdfDoc);
        if (options?.flatten) {
          await pdfDocCopyForDownload.flattenAnnotations(false);
        }
      }

      // document stamp insertion
      const stampSettings = dmsSettings?.stampSettings;

      // if no stamp settings are defined, we assume the default is that stamp insertion is disabled.
      if (stampSettings?.isStampInsertionOnDownloadEnabled && currentProject) {
        // assemble stamp content
        const { locale, position } = stampSettings;
        const date = new Date(); // current time
        const timezone = new Intl.DateTimeFormat(locale, { timeZoneName: 'short' }).formatToParts(date).find((part) => part.type === 'timeZoneName')?.value || '';
        const stampStrings = {
          locale,
          projectName: currentProject.name,
          downloadDate: {
            label: t('stamplabel_download', { defaultValue: 'Download', ns: 'pdfPreview', lng: locale }),
            value: `${date.toLocaleString(locale, { dateStyle: 'medium', timeStyle: 'short' })} ${timezone}`,
          },
          status: {
            label: t('stamplabel_status', { defaultValue: 'Status', ns: 'pdfPreview', lng: locale }),
            value: statusText,
          },
          versionNumber: {
            label: t('stamplabel_version', { defaultValue: 'Version', ns: 'pdfPreview', lng: locale }),
            value: versionText,
          },
        };

        // generate stamp document
        const stampDoc = await generateStampDocument(instance, stampStrings);
        const stampPage = await stampDoc.getPage(1);

        // init stamper
        const s = await core.PDFNet.Stamper.create(core.PDFNet.Stamper.SizeType.e_absolute_size, await stampPage.getPageWidth(), await stampPage.getPageHeight());
        await s.setAlignment(core.PDFNet.Stamper.HorizontalAlignment.e_horizontal_left, core.PDFNet.Stamper.VerticalAlignment.e_vertical_bottom);
        await s.setAsBackground(false);
        await s.setPosition(30, 30, false);
        const pgSet = await core.PDFNet.PageSet.createRange(1, await pdfDocCopyForDownload.getPageCount());

        // set stamp alignment based on project settings
        if (position === StampPosition.TopLeft) await s.setAlignment(core.PDFNet.Stamper.HorizontalAlignment.e_horizontal_left, core.PDFNet.Stamper.VerticalAlignment.e_vertical_top);
        else if (position === StampPosition.TopRight) await s.setAlignment(core.PDFNet.Stamper.HorizontalAlignment.e_horizontal_right, core.PDFNet.Stamper.VerticalAlignment.e_vertical_top);
        else if (position === StampPosition.BottomLeft) await s.setAlignment(core.PDFNet.Stamper.HorizontalAlignment.e_horizontal_left, core.PDFNet.Stamper.VerticalAlignment.e_vertical_bottom);
        else await s.setAlignment(core.PDFNet.Stamper.HorizontalAlignment.e_horizontal_right, core.PDFNet.Stamper.VerticalAlignment.e_vertical_bottom);

        // place stamps
        await s.stampPage(pdfDocCopyForDownload, stampPage, pgSet);
      }

      const data = await pdfDocCopyForDownload.saveMemoryBuffer(core.PDFNet.SDFDoc.SaveOptions.e_linearized);
      result = data.buffer;
    }, WEBVIEWER_LICENSE_KEY);

    // we don't change the viewed document, but we refresh anyway to make it obvious when changing the stamp code
    // causes the viewed document to be affected. Without this refresh, that could go unnoticed leading to ominous bugs.
    documentViewer.refreshAll();
    documentViewer.updateView();

    return result;
  }, [currentProject, dmsSettings]);
}
